import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'


// Elements
import ParseContent from 'components/shared/ParseContent'
import ImageBackground from 'components/shared/ImageBackground'
import TitleDefault from './TitleDefault'

const Hero = styled(ImageBackground)`
  @media (min-width: 992px) {
    min-height: 450px;
  }

  @media (max-width: 991px) {
    min-height: 300px;
  }
`

const Content = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  text-shadow: 0 0 4px rgba(0, 0, 0, .85);
`

const HeroService = ({ fields, title}) => (
  <Hero src={fields.image} aboveFold className="d-flex align-items-center">
    <div className="container py-5">
    </div>
  </Hero>
)

export default HeroService