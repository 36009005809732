import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import HeroService from 'components/elements/HeroService'
import Image from 'components/shared/Image'

const StyledImage = styled(Image)`
  width: 100%;
  height: 400px;
  object-fit: cover;
`

const Content = styled(ParseContent)`
 color: ${props => props.theme.color.text.black};

 & h2 {
   font-size: ${props => props.theme.font.size.l};
   font-weight: ${props => props.theme.font.weight['700']};
   color: ${props => props.theme.color.text.main};

   @media (max-width: 991px){
    font-size: ${props => props.theme.font.size.xm};
   }

 }

 ${props => props.withStripe && css`
  & h2 {
     margin-bottom: 30px;

     &:after {
      content: '';
      width: 37px;
      height: 3px;
      display: block;
      margin-top: 17px;
      background-color: ${props => props.theme.color.face.contrast};
    }
  }
 `}
  
`

const ServiceTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroService fields={acf.detailpage} title={title} />

      <section>
        <div className="container py-lg-3">
          <div className="row py-5">
            <div className="col-lg-9">
              <Content content={acf.detailpage.description} withStripe />
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpDiensten(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        inhoud {
          description
          icon {
            localFile {
              publicURL
            }
          }
        }
        detailpage {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default ServiceTemplate
